// eslint-disable-next-line
const UrlProd = 'https://formulacertaprodecommerce.fagrontech.com.br/';
// eslint-disable-next-line
const UrlDev = 'https://formulacertadev.azurewebsites.net/';

const devs = require('./devCustomers');

export default [
    { client: 'localhost', folder: 'localhost', tenantId: 'cfbdf8fb-0833-470c-82dc-77f0b7771809', urlApi: UrlDev },
    { client: 'belemaformulabr', folder: 'formula-belem', tenantId: 'F80FB6F0-A17F-47C2-AE44-1052A8DFA040', urlApi: UrlProd },
    { client: 'hidrattafarma', folder: 'hidrattafarma', tenantId: 'F72DBD15-9A40-4406-9FAC-CF031FE094B7', urlApi: UrlProd },
    { client: 'almofariz', folder: 'almofariz', tenantId: 'FC654FA1-033D-4961-BBE7-66018F63A8E8', urlApi: UrlProd },
    { client: 'farmaciam2m', folder: 'farmaciam2m', tenantId: '41EA7898-D5DA-4FDF-A4A3-0DC79DAE2366', urlApi: UrlProd },
    { client: 'dermoervas', folder: 'dermoervas', tenantId: 'b2a8bc0d-9866-4762-ada8-6209ab03dbfb', urlApi: UrlProd },
    { client: 'aformulabr', folder: 'formulabr', tenantId: 'E59E84CF-F221-4910-86C9-2D90E00C71C3', urlApi: UrlProd },
    { client: 'pagviaflora', folder: 'viaflora', tenantId: '00F6E6C7-2BBC-4444-B6E5-8D4385ACBBAA', urlApi: UrlProd },
    { client: 'pharmaco', folder: 'pharmaco', tenantId: '25CA445A-6B49-42CF-837E-77AB322947CF', urlApi: UrlProd },
    { client: 'pharmaecia', folder: 'pharmaecia', tenantId: '8b00ac2d-f02f-482f-a07e-8674bed67921', urlApi: UrlProd },
    { client: 'phusion-producao', folder: 'phusion-producao', tenantId: 'C2D9536E-2A42-46F6-AF66-9412F769AA13', urlApi: UrlProd },
    { client: 'equilibrio', folder: 'equilibrio', tenantId: '1c0647cd-8977-4b4b-933a-c4be8259318c', urlApi: UrlProd },
    { client: 'farmaciasempreviva', folder: 'sempreviva', tenantId: '19589B88-FBD4-48CA-BE1B-87A188A8ACD6', urlApi: UrlProd },
    { client: 'antiqua', folder: 'farmaceutica-antiqua', tenantId: '93f602ad-257b-48c4-b59b-4130cd7a720d', urlApi: UrlProd },
    { client: 'dermatologica', folder: 'farmacia-dermatologica', tenantId: 'ac75f285-3373-469a-aa2c-0d4802ad5138', urlApi: UrlProd },
    { client: 'jpfarmaciaroval', folder: 'farmacia-roval', tenantId: '6a1eb9b4-fd5f-403e-a13e-4910c4d23708', urlApi: UrlProd },
    { client: 'farmaciamonica', folder: 'farmacia-monica', tenantId: 'bed7daa5-667f-4080-a2d1-2f0cf8881d6b', urlApi: UrlProd },
    { client: 'farmaciaartpharma', folder: 'farmaciaartpharma', tenantId: 'd71f7040-ac9c-4624-930f-4dc27a86e40a', urlApi: UrlProd },
    { client: 'belladerm', folder: 'belladerm', tenantId: 'de3aa235-3760-4245-8c51-f814ec8d2553', urlApi: UrlProd },
    { client: 'artesanalfarmacia', folder: 'artesanalfarmacia', tenantId: 'db505d40-3f2a-4573-9c49-5c8d7640acfb', urlApi: UrlProd },
    { client: 'dermomoinhos', folder: 'dermomoinhos', tenantId: '65d949ae-077f-42bb-a136-a140d1c3b7e0', urlApi: UrlProd },
    { client: 'boticamagistral', folder: 'boticamagistral', tenantId: '8428242a-93c6-4818-8fef-d97ec184e63f', urlApi: UrlProd },
    { client: 'medsfarma', folder: 'medsfarma', tenantId: '942ff4d2-950f-4722-bbcb-027739cb38cd', urlApi: UrlProd },
    { client: 'doseexata', folder: 'doseexata', tenantId: '0348568f-4bfd-48aa-8e99-1f497cf62958', urlApi: UrlProd },
    { client: 'vidaanimal', folder: 'vidaanimal', tenantId: 'cf497da0-37ba-4193-a3c5-6959ae1804ed', urlApi: UrlProd },
    { client: 'primeformulas', folder: 'primeformulas', tenantId: '82c798dc-5f66-4384-862b-ab4b56bc8d00', urlApi: UrlProd },
    { client: 'anagallis', folder: 'anagallis', tenantId: '6b3e8df6-fece-49a3-9804-16dde937cfc3', urlApi: UrlProd },
    { client: 'new-unicafarmacia', folder: 'unicafarmacia', tenantId: '3e7afdad-ef79-4c0c-a760-0f0f176f4e38', urlApi: UrlProd },
    { client: 'farmaconde', folder: 'farmaconde', tenantId: 'b503adcb-9465-402c-abfb-1549822bf6c5', urlApi: UrlProd },
    { client: 'dermatologicamaringa', folder: 'dermatologicamaringa', tenantId: '3ab9f203-9ae2-44b3-97f5-beebd1555460', urlApi: UrlProd },
    { client: 'aformulavsf', folder: 'aformulavsf', tenantId: '8af30433-9073-49ea-924f-00180bf0a873', urlApi: UrlProd },
    { client: 'drogariaminasbrasil', folder: 'drogariaminasbrasil', tenantId: '1412e554-3fb4-46f6-883f-faec3961c64d', urlApi: UrlProd },
    { client: 'emporiodasformulas', folder: 'emporiodasformulas', tenantId: '11c5bfb2-4a64-42c4-b1f3-d699973f203b', urlApi: UrlProd },
    { client: 'farmagreen', folder: 'farmagreen', tenantId: '77490bd0-11e0-4986-a159-6cf614e89aea', urlApi: UrlProd },
    { client: 'formulaexata', folder: 'formulaexata', tenantId: '42984efa-f6d0-45a5-96a1-dc7593462eec', urlApi: UrlProd },
    { client: 'pharmaciaessencial', folder: 'pharmaciaessencial', tenantId: 'f5dc277c-d566-4861-8477-5c8c106a7462', urlApi: UrlProd },
    { client: 'farmaciabiomolecular', folder: 'farmaciabiomolecular', tenantId: 'a20ba77-0f83-442e-95fb-fd3267527f43', urlApi: UrlProd },
    { client: 'ophicinales', folder: 'ophicinales', tenantId: '36d48c22-c2b9-44d2-999b-a39dc82121be', urlApi: UrlProd },
    { client: 'saudevidafarmacia', folder: 'saudevidafarmacia', tenantId: '142ae911-4bc5-489d-9f89-6ce5a1246b53', urlApi: UrlProd },
    { client: 'jpfarmaciaroval', folder: 'jpfarmaciaroval', tenantId: '6a1eb9b4-fd5f-403e-a13e-4910c4d23708', urlApi: UrlProd },
    { client: 'dermaclass', folder: 'dermaclass', tenantId: '564693eb-4f70-4b8c-94e8-7ebcf7c53655', urlApi: UrlProd },
    { client: 'unofarma', folder: 'unofarma', tenantId: '5627c55e-4605-4a6c-9f09-5f8f24dda6c1', urlApi: UrlProd },
    { client: 'farmacerta', folder: 'farmacerta', tenantId: '2b437305-d6dd-4369-89db-972cd28141c2', urlApi: UrlProd },
    { client: 'naturale', folder: 'naturale', tenantId: 'b5be89c9-978f-4dfd-8707-3366f10d55dd', urlApi: UrlProd },
    { client: 'suafarmacia', folder: 'suafarmacia', tenantId: '026a887e-dccd-40ba-8e35-312608d2c097', urlApi: UrlProd, fakeCard: true},
    { client: 'sua-farmacia', folder: 'sua-farmacia', tenantId: '559EB2E4-8C21-4738-AC9C-C1DCB4F211FA', urlApi: UrlProd, fakeCard: true},
    { client: 'fcertaprod', folder: 'fcertaprod', tenantId: 'E96EA31B-C8B4-4E12-8F71-834ADF221E3F', urlApi: UrlProd },
    { client: 'lantana', folder: 'lantana', tenantId: 'c20959ee-d9ab-48f5-b988-722e0218e4e5', urlApi: UrlProd },
    { client: 'comercial', folder: 'comercial', tenantId: '964390f0-e166-4b14-a875-a83c854a6eb6', urlApi: UrlProd, fakeCard: true },
    { client: 'amphora', folder: 'amphora', tenantId: '63ee7423-2fb5-467f-925e-9b9673dd8de0', urlApi: UrlProd },
    { client: 'formulacerta', folder: 'formulacerta', tenantId: '09e56d69-532f-485b-bde1-5f9cdb0f8e8a', urlApi: UrlProd },
    //{ client: 'divinaformula', folder: 'farmacia-divina', tenantId: '5fab0761-2e2a-4615-a843-926757115026', urlApi: UrlProd },
    { client: 'amadah', folder: 'farmacia-amadah', tenantId: '83930dfe-c943-4708-bae9-f1c8c26f9f86', urlApi: UrlProd },
    { client: 'apotheke', folder: 'farmacia-apotheke', tenantId: 'd48accf5-e853-4234-bd30-94cc1c8cb7d3', urlApi: UrlProd },
    { client: 'curante', folder: 'farmacia-curante', tenantId: '2c2a9b29-e19c-42a3-842e-89eb68f45829', urlApi: UrlProd },
    { client: 'aformulaal', folder: 'farmacia-aformulaal', tenantId: '24929bb8-3ffd-41f4-a592-6fe4a6e6ff95', urlApi: UrlProd },
    { client: 'byoderma', folder: 'farmacia-byoderma', tenantId: 'a7de1c4e-b8fd-421f-a674-e82c1f5e888f', urlApi: UrlProd },
    { client: 'rovaljuazeirodonorte', folder: 'farmacia-rovaljuazeirodonorte', tenantId: 'F201D886-D827-48FC-A55F-8B21A6ADACCE', urlApi: UrlProd },
    { client: 'floramed', folder: 'farmacia-floramed', tenantId: 'fa1543e7-7a00-433c-851c-c0d8f063094e', urlApi: UrlProd },
    { client: 'farmaciabyoderma', folder: 'farmacia-byoderma2', tenantId: '9360dc5f-8e60-4a46-b01f-f747f12d2a78', urlApi: UrlProd },
    { client: 'boticaderossi', folder: 'farmacia-boticaderossi', tenantId: 'D3DA0FF2-E9AD-418B-ABA6-473CDE204E23', urlApi: UrlProd },
    { client: 'naturefarm', folder: 'farmacia-naturefarm', tenantId: '6640A3CD-9741-4A93-AA2A-43B0788BEA43', urlApi: UrlProd },
    { client: 'new-paguemenos', folder: 'farmacia-paguemenos', tenantId: 'd9cbb635-b9ed-4bc6-a7aa-26f4b0ed7093', urlApi: UrlProd },
   // { client: 'new-dermage', folder: 'farmacia-dermage', tenantId: '7e3f3a96-7621-423a-bebb-3a0783fb36e0', urlApi: UrlProd },
    { client: 'uniformulas', folder: 'farmacia-uniformulas', tenantId: 'dc00b0dd-1cdd-4e44-b57e-c8613712e174', urlApi: UrlProd },
    { client: 'medicinalnaweb', folder: 'farmacia-medicinalnaweb', tenantId: '82b13fb5-e712-4279-bd9b-6a631ded4a9a', urlApi: UrlProd },
    { client: 'new-farmaciaterapeutica', folder: 'farmacia-terapeutica', tenantId: 'a680bfc8-9c9d-48e8-808b-c4abcb57b65d', urlApi: UrlProd },
    { client: 'farmaciamagistral', folder: 'farmacia-magistral', tenantId: '0c53cc4c-1512-4bff-91fd-7b01012ba06a', urlApi: UrlProd },
    { client: 'farmaciacriativa', folder: 'farmacia-criativa', tenantId: 'ecdb1057-d25c-472b-8479-414ffe3c6b13', urlApi: UrlProd },
    { client: 'new-singularpharma', folder: 'farmacia-singularpharma', tenantId: '4B6EBC58-D7BC-42C5-80A3-930C9EF8C2CA', urlApi: UrlProd },
    { client: 'farmaciaroval', folder: 'farmaciaroval', tenantId: '6b9da299-88d4-4a7e-a60d-beadb82f6b75', urlApi: UrlProd },
    { client: 'farmaciadaterra', folder: 'farmaciadaterra', tenantId: '7deb9c18-e917-4c94-ba9f-0e5ca3c0e9d5', urlApi: UrlProd },
    { client: 'dermadia', folder: 'farmacia-dermadia', tenantId: 'b7dd643e-a090-4343-8237-b89e87d7bda2', urlApi: UrlProd },
    { client: 'roval', folder: 'roval', tenantId: '9e1782cf-5fc2-40b0-b742-15f488de6371', urlApi: UrlProd },
    { client: 'luzefarma', folder: 'luzefarma', tenantId: '380c5ea1-f6df-42ab-9cf9-d3f1222e18c9', urlApi: UrlProd },
    { client: 'unifarma', folder: 'unifarma', tenantId: '97bcade5-7a42-4b42-8e93-19d8ebf28ac1', urlApi: UrlProd },
    { client: 'maisnaturelle', folder: 'maisnaturelle', tenantId: '41afec16-581e-476e-b0ab-857d64b3ab62', urlApi: UrlProd },
    { client: 'ervadoce', folder: 'ervadoce', tenantId: '3e0985ee-33da-445f-9d68-e76657da927f', urlApi: UrlProd },
    { client: 'farmaformulapb', folder: 'farmaformulapb', tenantId: '47151b15-39a3-44cc-90e5-4158aff963d5', urlApi: UrlProd },
    { client: 'farmaformulacz', folder: 'farmaformulacz', tenantId: 'b1c76622-4252-4fd0-8fe1-0cd4f580d7b7', urlApi: UrlProd },
    { client: 'doseindicada', folder: 'doseindicada', tenantId: '56be1715-dc94-42c5-9310-4405e352a92a', urlApi: UrlProd },
    { client: 'maxpharma', folder: 'maxpharma', tenantId: 'd66579c8-40c6-4f12-aada-b2f74f75d568', urlApi: UrlProd },
    { client: 'manipulatta', folder: 'manipulatta', tenantId: '12d7866c-b105-4626-a0ba-3ebae8f662a3', urlApi: UrlProd },
    { client: 'minhaformula', folder: 'minhaformula', tenantId: '1a693cfb-15c4-4258-b731-24f1033c85a5', urlApi: UrlProd },
    { client: 'farmaciaverdevida', folder: 'farmaciaverdevida', tenantId: '7b5992d8-efc7-491d-9058-032ea36f1b01', urlApi: UrlProd },
    { client: 'vitallefarmaciamanupulacao', folder: 'vitallefarmaciamanupulacao', tenantId: '6be27254-4319-4535-b887-66487300a722', urlApi: UrlProd },
    { client: 'rovalcampinagrande', folder: 'rovalcampinagrande', tenantId: '86316926-153c-42f4-a6bb-0747a4722dc0', urlApi: UrlProd },
    //{ client: 'farmaformulapi', folder: 'farmaformulapi', tenantId: '4c61d0d3-df50-4123-89ce-854d80a02ff8', urlApi: UrlProd },
    { client: 'pharmapele', folder: 'pharmapele', tenantId: '1e8e03c5-f407-4721-b50c-5c6c86efc431', urlApi: UrlProd },
    { client: 'miyufarma', folder: 'miyufarma', tenantId: 'fdee7380-5583-4804-b841-85ccd4b9b86b', urlApi: UrlProd },
    { client: 'pharmapelearcoverde', folder: 'pharmapelearcoverde', tenantId: '019184bc-f82b-4271-82a0-a9477e7ffe44', urlApi: UrlProd },
    { client: 'brabofarmacia', folder: 'brabofarmacia', tenantId: 'a4d9f23f-27dc-4e67-8bb1-77f9ae0b62d9', urlApi: UrlProd },
    { client: 'dermaativa', folder: 'dermaativa', tenantId: '486fff48-7111-4d9b-a969-f921b7effb82', urlApi: UrlProd },
    { client: 'promanipulacao', folder: 'promanipulacao', tenantId: 'd9d591e0-7143-4c82-8932-0365b7a2a1f8', urlApi: UrlProd },
    { client: 'kallium', folder: 'kallium', tenantId: 'ed531e8a-3537-4e22-b05a-58186c988bdc', urlApi: UrlProd },
    { client: 'ecofarma', folder: 'ecofarma', tenantId: '3a533f58-4d88-4786-83ee-66a60b56b988', urlApi: UrlProd },
    { client: 'biopluspharma', folder: 'biopluspharma', tenantId: '3e979895-4cb3-44ae-9812-4c3134257b64', urlApi: UrlProd },
    { client: 'fhn', folder: 'fhn', tenantId: '53ecf572-057d-4f59-bb1d-3fb4b4c214fe', urlApi: UrlProd },
    { client: 'acacia', folder: 'acacia', tenantId: 'c24ba498-fddc-47e2-acae-d530d99a152e', urlApi: UrlProd },
    { client: 'biomagistral', folder: 'biomagistral', tenantId: '1f834268-34f6-49aa-96c2-84d0e03c19fc', urlApi: UrlProd },
    { client: 'revitallepharma', folder: 'revitallepharma', tenantId: '8a6257ef-d1a9-4a20-9f77-32381575a261', urlApi: UrlProd },
    { client: 'farmacialiane', folder: 'farmacialiane', tenantId: 'f94a49c0-7d01-4b0f-8dc1-232bf1a4aea3', urlApi: UrlProd },
    { client: 'farmaciabiologica', folder: 'farmaciabiologica', tenantId: '7010831f-568e-481d-9804-df1a868a233a', urlApi: UrlProd },
    { client: 'artesanalcampinas', folder: 'artesanalcampinas', tenantId: 'a76ae83d-5336-4c42-8340-1db535591400', urlApi: UrlProd },
    { client: 'similia', folder: 'similia', tenantId: '8158ae91-de5a-4dbb-9438-e7093f05b6f5', urlApi: UrlProd },
    { client: 'freitasmanipulacoes', folder: 'freitasmanipulacoes', tenantId: 'f4fcd707-78ce-4b7c-a6be-68bad5856ce8', urlApi: UrlProd },
    { client: 'farmaflora', folder: 'farmaflora', tenantId: 'b308b5fb-3350-4780-b7b4-eeefa52d8633', urlApi: UrlProd },
    { client: 'farmaformula', folder: 'farmaformula', tenantId: '833d7bf8-f48a-432a-bcb5-87150d51cebc', urlApi: UrlProd },
    { client: 'formuladosfarma', folder: 'formuladosfarma', tenantId: '441beb93-2337-4e9c-b81b-0827a4156b25', urlApi: UrlProd },
    { client: 'nitratus', folder: 'nitratus', tenantId: '958F431C-7B43-4AB1-BDE5-D6033D0B88D3', urlApi: UrlProd },
    { client: 'finnofarma', folder: 'finnofarma', tenantId: '19B724FD-0BED-4373-8BE0-4A290500F95F', urlApi: UrlProd },
    { client: 'santabarbara', folder: 'santabarbara', tenantId: 'adb008d8-6d0c-4295-8ce7-a7c1d974540f', urlApi: UrlProd },
    { client: 'biovittare', folder: 'biovittare', tenantId: '9b03b460-b36c-4e98-a802-b975ca359a87', urlApi: UrlProd },
    { client: 'squadlastjedi', folder: 'squadlastjedi', tenantId: '34d5b3bf-e94b-45b7-806e-61e040acaa39', urlApi: UrlProd },
    { client: 'divinaformula1', folder: 'divinaformula1', tenantId: 'a6972779-5a32-429e-938c-2621b7cc9908', urlApi: UrlProd },
    { client: 'timefcerta', folder: 'timefcerta', tenantId: '6706aa4c-da19-4aa4-8b73-941caa3e7fce', urlApi: UrlProd },
    { client: 'dermage', folder: 'dermage', tenantId: '7e3f3a96-7621-423a-bebb-3a0783fb36e0', urlApi: UrlProd },

    ...devs
];
